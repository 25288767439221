import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Paragraph from "../components/Paragraph";
import ProjectListItem from "../components/ProjectListItem";
import SectionHeader from "../components/SectionHeader";
import SkillsList from "../components/SkillsList";
import bugtrackerCollage from "../../static/bugtracking_collage_less_border.png";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import WIPProjectListItem from "../components/WIPProjectListItem";
import SmallAnimatedLink from "../components/SmallAnimatedLink";

const ColorAccent = styled.span`
    animation: change 2.5s ease both;
    @keyframes change {
        from { color: black }
        to { color: hsl(153,54%,43%)}
    }
`;

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Justin Thurman - Web Developer</title>
      </Helmet>
      <Layout>
        <PageHeader>
          I’m <ColorAccent>Justin</ColorAccent>, a web developer based
          out of Atlanta, GA
        </PageHeader>
        <Paragraph top>
          My introduction to web development began as an economics
          master’s student, where I used R and Python to perform data
          analysis. I wanted to deploy some of the tools I created on
          the web, which led me to learn Django, and I fell in love.
          Since then, I’ve been learning Javascript to expand my front
          end skillset and really see what the world of web
          development has to offer.
        </Paragraph>
        <Paragraph>
          I’ve been fortunate enough to be guided by some fantastic
          mentors who have shown me what’s possible in a web
          application and helped me go from “hello world” to
          real-world, practical products, stressing the importance of
          strong fundamentals and deliberate design. I’ve loved every
          step. The more I learn, the more I want to learn.
        </Paragraph>
        <Paragraph>
          I am currently employed at <SmallAnimatedLink
            as="a"
            href="https://www.chromatic.com/"
            target="_blank"
          >
            Chromatic
          </SmallAnimatedLink>
          .
        </Paragraph>

        <SectionHeader>My Skills</SectionHeader>
        <Paragraph top>
          I am self-taught, but from the beginning, I knew I wanted to
          learn to code with a professional’s mindset. Thankfully, I
          have become friends with professional developers who have
          helped me find that path. I have always tried to establish
          strong fundamentals and then apply those fundamentals to
          real-world projects.
        </Paragraph>
        <SkillsList />

        <SectionHeader>My Projects</SectionHeader>
        <ProjectListItem
          href="bugtracking"
          img={bugtrackerCollage}
          title="Bugtracking.io"
        >
          A team-based tool for tracking bugs and managing projects.
        </ProjectListItem>
        <WIPProjectListItem
          title="Tag.recipes"
        >
          A work-in-progress app for cataloging and organizing recipes, featuring a drag-and-drop tagging interface. Built with React and Cloud Firestore.
        </WIPProjectListItem>
      </Layout>
      <Footer>
        <ContactForm />
      </Footer>
    </>
  );
}
